/**
 * Form Validator custom validations
 * @memberof module:foundation/Forms
 * @author Rocco Janse <rocco.janse@valtech.nl>
 * @version 1.0.0
 */
const CustomValidations = {
    regex: function (fieldNode, pattern) {
        var regex = new RegExp(pattern);
        return regex.test(fieldNode.value);
    },

    /**
     * Checks if value of current field is equal to otherfield's value.
     * @param {node} fieldNode DOM element of current field.
     * @param {string} otherField CSS selector of other field.
     * @returns {boolean} true or false.
     */
    equal: function (fieldNode, otherField) {
        return fieldNode.value === document.querySelector(otherField).value;
    },

    /**
     * Checks if value is a valid phone number for various countries.
     * @param {node} fieldNode DOM element of current field.
     * @param {string} [countryCode=nl] Optional countrycode (nl).
     * @returns {boolean} true or false
     */
    telephone: function (fieldNode, countryCode) {
        var bool = false,
            tel = null,
            mobile = null,
            code = countryCode || 'nl';

        switch (code) {
            default: {
                tel = /^(((0)[1-9]{2}[0-9][-]?[1-9][0-9]{5})|((\+31|0|0031)[\s-]?[1-9][0-9][-]?[1-9][0-9]{6}))$/;
                mobile = /^(((\+31|0|0031)[\s-]?6){1}[1-9]{1}[0-9]{7})$/i;
                bool = tel.test(fieldNode.value) || mobile.test(fieldNode.value);
                break;
            }
        }
        return bool;
    },

    /**
     * Checks if value is a valid zipcode for various countries.
     * @param {node} fieldNode DOM element of current field.
     * @param {string} [countryCode=nl] Optional countrycode (be/de/nl).
     * @returns {boolean} true or false
     */
    zipcode: function (fieldNode, countryCode) {
        var bool = false,
            code = countryCode || 'nl';

        switch (code) {
            case 'be': {
                bool = /^[1-9]{1}[0-9]{3}$/i.test(fieldNode.value) ? false : true;
                break;
            }
            case 'de': {
                bool = /^[1-9]{1}[0-9]{4}$/i.test(fieldNode.value) ? false : true;
                break;
            }
            default: {
                bool = /^[1-9]{1}[0-9]{3}[\s-]?[a-zA-Z]{2}$/i.test(fieldNode.value) ? true : false;
                break;
            }
        }
        return bool;
    },

    /**
     * Checks if value is a valid IBAN number.
     * @param {node} fieldNode DOM element of current field.
     * @returns {boolean} true or false
     */
    iban: function (fieldNode) {
        return /^[a-zA-Z]{2}[0-9]{2}[a-zA-Z0-9]{4}[0-9]{7}([a-zA-Z0-9]?){0,16}$/i.test(fieldNode.value);
    },

    /**
     * Checks if selected file doesn't exceed max upload size
     * @param {node} fieldNode
     * @returns {boolean} true or false
     */
    maxsize: function (fieldNode) {
        let bool = false;
        if (fieldNode.getAttribute('data-val-maxsize')) {
            let maxsize = fieldNode.getAttribute('data-val-maxsize');
            let filesize = $(fieldNode).prop('files')[0].size / 1024 / 1024;
            bool = filesize > maxsize ? false : true;
            let msg = $(fieldNode).siblings('.invalid-feedback--msg');
            if ((bool === false) & (msg !== undefined)) {
                $(msg).addClass('size-exceeded-msg');
            } else {
                $(msg).removeClass('size-exceeded-msg');
            }
            return bool;
        } else {
            return bool;
        }
    },
};

export default CustomValidations;
