/**
 * Base Forms Functionality.
 *
 * @module foundation/Forms
 */
import 'jquery.easing';
import 'jquery.scrollto';
import customValidations from './lib/form-validator-validations.js';
import errorMessages from './lib/form-validator-messages.js';

/**
 * Validates forms by hooking into HTML5 validation.
 * Add custom validations by adding data atributes.
 *
 * @requires jquery.easing
 * @requires jquery.scrollto
 * @requires form-validator-validations
 * @requires form-validator-messages
 * @memberof module:foundation/Forms
 * @version 0.5.0
 * @author Rocco Janse <rocco.janse@valtech.nl>
 */
class FormValidator {
    /**
     * Upgrades form and initializes.
     * @param {node} element DOM node of form.
     */
    constructor(element) {
        // current form
        this.$form = $(element);
        this.$submitButton = this.$form.find('[type=submit]');

        this.attrStart = 'data-val-';
        this.isFormValid = false;
        this.fieldsToValidate = [];

        this.$loader = this.$form.find('.loader').hide();

        this.disableSubmit = false;
    }

    /**
     * Handles collapsibles, submit and gathers fields to validate.
     */
    init() {
        // add data attribute to form to prevent HTML5 validation
        this.$form.attr('novalidate', true);

        // handle collapsibles
        this.collapsibles = this.$form.find('.collapse');
        this.collapsibles.on('shown.bs.collapse hidden.bs.collapse', this.update.bind(this));

        // handle submit
        this.$submitButton.on('click.validator', this.handleSubmit.bind(this));

        // gather fields to validate and update validation
        this.update();
    }

    /**
     * Gathers fields to validate and adds events to fields to enable validation.
     */
    update() {
        this.fieldsToValidate = [];
        let events = '';

        // get element nodes from form node
        for (let fieldNode of this.$form[0].elements) {
            // ignore optional fields, buttons, fieldsets, disabled, readonly and hidden etc.
            if (
                (fieldNode.nodeName !== 'INPUT' &&
                    fieldNode.nodeName !== 'TEXTAREA' &&
                    fieldNode.nodeName !== 'SELECT') ||
                fieldNode.disabled === true ||
                fieldNode.readOnly === true ||
                $(fieldNode).is(':hidden') === true
            ) {
                continue;
            }

            // add change event especially for file inputs
            events = 'blur.validator';
            if (fieldNode.type === 'file') {
                events = ' change.validator';
            }

            // add events
            $(fieldNode)
                .off(events)
                .on(events, (e) => {
                    this.updateField(e.currentTarget);
                });

            // add field to validation array
            this.fieldsToValidate.push(fieldNode);
        }

        this.updateSubmitButton();
    }

    /**
     * Validates field and updates submit button.
     * @param {node} fieldNode DOM node.
     */
    updateField(fieldNode) {
        this.validateField(fieldNode);
        this.updateSubmitButton();
    }

    /**
     * Enables or disables submitbutton to prevent submittion of non-valid form.
     * If disableSubmit is set to true.
     */
    updateSubmitButton() {
        if (this.disableSubmit) {
            let isValid = true;
            for (const fieldNode of this.fieldsToValidate) {
                if (!fieldNode.validity.valid) {
                    isValid = false;
                    continue;
                }
            }

            if (!isValid) {
                this.$submitButton.addClass('disabled').prop('disabled', true);
            } else {
                this.$submitButton.removeClass('disabled').prop('disabled', false);
            }
        }
    }

    /**
     * Validates current form fields and returns validation status.
     * @returns {boolean} true or false.
     */
    validate() {
        let isValid = true;
        for (const fieldNode of this.fieldsToValidate) {
            if (!this.validateField(fieldNode)) {
                isValid = false;
                continue;
            }
        }
        return isValid;
    }

    /**
     * Validates field and updates field validation status.
     * @param {node} fieldNode DOM node.
     */
    validateField(fieldNode) {
        let $parent = $(fieldNode).parents('.form-group, .form-check');
        const validatorAttributes = this.getElementAttributes(fieldNode, this.attrStart);
        let validationMsg = '';

        // first, do default HTML5 validation on node
        fieldNode.setCustomValidity('');

        if (
            typeof $(fieldNode)
                .parents('.form-group')
                .attr(this.attrStart + 'requiredgroup') !== 'undefined'
        ) {
            // requiredgroup validation (needs to have a minimum of one item selected)
            $parent = $(fieldNode).parents('.form-group');
            const groupFields = $parent.find('input');
            let isGroupValid = false;

            for (const groupFieldNode of groupFields) {
                if (groupFieldNode.checked) {
                    isGroupValid = true;
                    break;
                }
            }

            // set (in-)valid and show/hide feedback
            if (isGroupValid) {
                for (const groupFieldNode of groupFields) {
                    groupFieldNode.setCustomValidity('');
                    $parent.removeClass('is-invalid').addClass('is-valid');
                }
            } else {
                validationMsg = $parent.attr(this.attrStart + 'requiredgroup-msg') || errorMessages.badInput;
                for (const groupFieldNode of groupFields) {
                    groupFieldNode.setCustomValidity(validationMsg);
                    $parent.find('.invalid-feedback--message').text(validationMsg);
                    $parent.removeClass('is-valid').addClass('is-invalid');
                }
            }
        } else if ($(fieldNode).val()) {
            // custom validations
            if (!$.isEmptyObject(validatorAttributes)) {
                for (const attribute in validatorAttributes) {
                    const attrName = attribute.replace(this.attrStart, '');
                    let validation;

                    // ignore messages
                    if (attrName.indexOf('-msg') === -1) {
                        validation = attrName;

                        // do custom validation, if exists
                        // if false; break the loop and show error
                        if (typeof customValidations[validation] !== 'undefined') {
                            if (!customValidations[validation](fieldNode, validatorAttributes[attribute])) {
                                // not valid, set custom validation message
                                validationMsg = validatorAttributes[attribute + '-msg'] || errorMessages.badInput;
                                fieldNode.setCustomValidity(validationMsg);
                                $parent.find('.invalid-feedback--msg').text(validationMsg);

                                // break if not valid
                                break;
                            } else {
                                // validate field
                                fieldNode.setCustomValidity('');
                            }
                        } else {
                            // could not validate, so accept
                            fieldNode.setCustomValidity('');
                            // but issue warning in console
                            console.warn(`Custom validation "${validation}" not defined or found.`);
                        }
                    }
                }
            }
        }

        // set validity
        fieldNode.checkValidity();

        // immediate feedback
        if (fieldNode.validity.valid) {
            $(fieldNode).removeClass('is-invalid').addClass('is-valid');
        } else {
            $(fieldNode).removeClass('is-valid').addClass('is-invalid');
        }

        return fieldNode.validity.valid;
    }

    /**
     * Submits form if all fields are validated.
     * @param {object} event
     */
    handleSubmit(e) {
        e.preventDefault();

        // disable submit button
        this.$submitButton.addClass('disabled').prop('disabled', true);

        this.$loader.fadeIn(200, () => {
            if (this.validate()) {
                // do submit
                this.$form.submit();
            } else {
                // form not valid, add class to form
                this.$form.addClass('was-validated');

                // hide loader
                this.$loader.fadeOut(200, () => {
                    // scrollto first error
                    let offset = 0 - parseInt($(document.body).css('padding-top')) - 80;
                    $.scrollTo(this.$form.find('.is-invalid').first(), 250, {
                        axis: 'y',
                        offset: {
                            top: offset,
                        },
                        easing: 'easeInOutQuad',
                        onAfter: () => {
                            // enable submit button
                            this.$submitButton.removeClass('disabled').prop('disabled', false);
                        },
                    });
                });
            }
        });
    }

    /**
     * Returns element attributes where name starts with string.
     * @param {node} el DOM node to get attributes from.
     * @param {string} [str] Optional string of attribute start.
     * @returns {array} Array of attributes found.
     */
    getElementAttributes(node, str) {
        let attrs = {};
        for (const attribute of node.attributes) {
            if (str) {
                if (attribute.name.indexOf(str) === 0) {
                    attrs[attribute.name] = attribute.value;
                }
            } else {
                attrs[attribute.name] = attribute.value;
            }
        }
        return attrs;
    }

    /**
     * Checks for empty values.
     * @param {string} str String to check/
     */
    isEmpty(str) {
        return !str.replace(/^\s+/g, '').length;
    }
}

// register to Component Handler
window.ComponentHandler.register({
    constructor: FormValidator,
    classAsString: 'FormValidator',
    cssClass: 'js-validate-form',
});

export default FormValidator;
