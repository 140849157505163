/**
 * Fallback Generic Form Validator default messages
 * Default HTML validity messages.
 * @memberof module:foundation/Forms
 * @author Rocco Janse <rocco.janse@valtech.nl>
 * @version 1.0.0
 */
const FormValidatorMessages = {
    valueMissing: 'This field is required.',
    badInput: 'This value is not correct.',
    patternMismatch: 'This value is not valid.',
    rangeOverflow: 'This value exceeds the maximum value.',
    rangeUnderflow: 'This value is less that the minimum value.',
    stepMismatch: 'This value is not evenly divisible by the step value.',
    tooLong: 'This value is too long.',
    tooShort: 'This value is too short.',
    typeMismatch: 'This value is not correct.',
    requireGroup: 'Please select at least one option.',
};

export default FormValidatorMessages;
